@import '../../styles/variables';

.loaderBackdrop {
  z-index: 1300;
  display: flex;
  align-items: center;
  justify-content: center;
  &.fullScreen {
    background: $loader-background !important;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
  background: transparent;
  .loaderContainer {
    min-width: 100%;
    color: var(--medium-blue);
    text-align: center;
  }
}
