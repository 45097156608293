@import '../../styles/variables';

$load-more-btn-height: 2.678rem;
$no-data-message-background-color: $white;
$no-data-message-border-color: var(--black-30);

.cards {
  background: #ffffff;
  box-shadow: 0px 0px 12px rgb(0 0 0 / 10%);
  border-radius: 16px;
}

.cardText {
  font-style: normal;
  font-weight: 600;
  font-size: 1.1vw;
  line-height: 24px;
  align-items: center;
  text-align: center;
  color: #2514be;
}

.regionContainer {
  min-height: 100%;
  .regionsList {
    padding: 1.5rem 0 1.5rem;
    .noData {
      background-color: $no-data-message-background-color;
      border-radius: 0.75rem;
      border: 0.5px solid $no-data-message-border-color;
    }
  }
}
.downloadHeading {
  color: $field-label-color;
  font-size: 1.2rem;
}
.downloadText {
  color: var(--primary-title-text-color);
  font-size: 1rem;
}

.downloadCopyright {
  color: var(--primary-title-text-color);
  font-size: 0.75rem;
}
